var ListOfStatesObject = (function () {

    var _list = {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    };

    return {
        list: _list
    };

})();

 (function ($, window, States: any) {

    var Custom = {

        init: function (config) {
            this.config = config;
            this.displayStateContent(window.location.pathname);
            this.displayStateElements(this.config.currentState);
            this.changeSpanishLink(this.config.spanishButton);
        },

        displayStateContent: function (state) {
            var $this = this.config;

            if (state === '/Texas-online-driver-education.aspx') {
                $this.bottomContent.removeClass('hide');
                $this.endorseBanner.addClass('hide');
                $this.CustomerReviewsBanner.addClass('custmor-rev-full-width')
                $this.topContent.hide()
                    .load('/js/includes/Texas_content.html', function () {
                        $(this).show()
                    });

				/* Adds the #C2126 text next to Texas state title
				  on state landing page */
                $this.landingPageState.removeClass('hide');
            }
        },

        displayStateElements: function (currentState) {
            var $this = this.config;

            if ($.trim(currentState.text()) === 'Texas') {
                $this.faqPricePoint.addClass('hide');

                var schoolCode = $('<small/>', {
                    'class': 'school-code',
                    text: 'Stay Safe Driving School | C2126'
                });

                currentState.after(schoolCode);
            }
        },

        changeSpanishLink: function (spButton) {
            var currentState = $.trim($('h1.state-select-head').text());

            if (currentState === 'Texas') { return; }

            var newLink = window.location.href;

            newLink = '/states/spanish.aspx?statecode=';
            newLink += Custom.getStateAbbrev(currentState);

            spButton.attr('href', newLink);
        },

        getStateAbbrev: function (fullStateName) {
            for (var state in States.list) {
                if (States.list[state] === fullStateName) {
                    return state;
                }
            }
        },

        // https://hashnode.com/post/how-to-get-the-local-time-of-a-particular-timezone-in-javascript-cj703pkwg01t9s5wt8zh37rwz
        calculateTimeInTimeZone: function (offset) {
            var d = new Date();
            var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
            var nd = new Date(utc + (3600000 * offset));
            return nd;
        }
    };

    Custom.init({
        CustomerReviewsBanner: $('.custmor-rev'),
        topContent: $('.top-content'),
        bottomContent: $('.bottom-content'),
        faqPricePoint: $('.online-ed-price .price'),
        currentState: $('.states-heading > h3'),
        landingPageState: $('.school-number'),
        spanishButton: $('.spanish_btn'),
        endorseBanner: $('.grey-endorse')
    });

})(jQuery, window, ListOfStatesObject || {});