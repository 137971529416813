import * as $ from 'jquery';

declare const YT: any;

let player;
function onYouTubeIframeAPIReady() {
    player = new YT.Player('player', {
        height: '250',
        width: '444',
        videoId: 'SyNw9W_GcUI',
        events: {
            'onReady': onPlayerReady
        }
    });
}

function onPlayerReady(event) {
    event.target.playVideo();
}

$(document).ready(function () {
    $(".state-selector select").addClass('btn-warning');
    $(".state-selector select option:first-child").text("ENROLL TODAY");

    $(".state-selector select").change(function (evt) {
        const statecode = $(evt.target).find('option:selected').attr('code');
        document.location.href = "/states/default.aspx?statecode=" + statecode;
    });
    
    $(".video-player .placeholder").on('click', function () {
        $('.video-player').addClass('playing');
        ($('.video-player video')[0] as any).play();
    })

    $('#btn-ask-parent').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const emailPattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        const data = {
            userFirstName: ($('#user-first-name').val().toString() || '').trim(),
            userEmail: ($('#user-email').val().toString() || '').trim(),
            userParentEmail: ($('#user-parent-email').val().toString() || '').trim()
        };

        if (data.userFirstName && data.userEmail && data.userParentEmail && emailPattern.test(data.userEmail) && emailPattern.test(data.userParentEmail)) {
            $.ajax({
                type: 'POST',
                url: 'Default.aspx/SendEmailToParents',
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    if (data.d) {
                        $('.ask-panel').hide();
                        $('.thankyou-panel').show();
                    }
                    else {
                        alert('Sorry, cannot send e-mail. Try again later.')
                    }
                }
            });
        }
        return false;
    });

    setTimeout(() => {
        ($('.partner-slider') as any).lightSlider({
            item: 5,
            //autoWidth: true,
            loop: false,
            slideMove: 1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 600,
            onSliderLoad: function () {
                $('.partner-slider').removeClass('hidden');
            },
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: 3,
                        slideMove: 1,
                        slideMargin: 6,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: 1,
                        slideMove: 1
                    }
                }
            ]
        });
    }, 1000);
});
