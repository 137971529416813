function ReferCookie() {
    var SEARCHQUERYSTRING = ["q", "query", "p", "keywords"];

    this.createCookie = function (name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    this.readCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    this.eraseCookie = function (name) {
        createCookie(name, "", -1);
    }

    this.getParameterByName = function (url, name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS, "i");
        var results = regex.exec(url);
        if (results == null)
            return null;
        else
            return decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    this.calculateSearchPhrase = function (url) {
        var len = SEARCHQUERYSTRING.length;
        for (var i = 0; i < len; i++) {
            var value = SEARCHQUERYSTRING[i];
            var searchquery = this.getParameterByName(url, value);
            if (searchquery) {
                return searchquery;
            }
        }
        return null;
    }

    this.getReferCookieValue = function () {
        var referrer = document.referrer;
        if (!referrer || referrer.length == 0) {
            return null;
        }
        var searchphrase = this.calculateSearchPhrase(referrer);
        var host = referrer.split('/')[2];
        if(this.getParameterByName(window.location.href,'gclid')) {
            host = "google (cpc)";
        } else if (this.getParameterByName(window.location.href,'ysmwa')) {
            host = "yahoo (cpc)";
        }
        if (searchphrase) {
            return "#"+host + "&" + searchphrase;
        }
        else {
            return "#"+host;
        }
    }

    this.getUtmCookieValue = function () {
        var referrer = document.referrer;
        if (!referrer || referrer.length == 0) {
            return null;
        }

        var utmSource = this.getParameterByName(window.location.href, 'utm_source');
        var utmMedium = this.getParameterByName(window.location.href, 'utm_medium');
        var utmTerm = this.getParameterByName(window.location.href, 'utm_term');
        var utmContent = this.getParameterByName(window.location.href, 'utm_content');
        var utmCampaign = this.getParameterByName(window.location.href, 'utm_campaign');

        if (!utmSource || !utmMedium || !utmTerm || !utmContent || !utmCampaign) return null;

        var utmParams = {
            utmSource: utmSource,
            utmMedium: utmMedium,
            utmTerm: utmTerm,
            utmContent: utmContent,
            utmCampaign: utmCampaign
        }

        return window.btoa(JSON.stringify(utmParams));
    }

    this.saveReferCookie = function () {
        var refcook = this.readCookie("VISITREFER");
        if (!refcook)
        {
            // calculate refercookie
            var refercookievalue = this.getReferCookieValue();
            if (!refercookievalue)
                refercookievalue = "direct";

            var utmcookievalue = this.getUtmCookieValue();
            if (utmcookievalue) {
                refercookievalue = utmcookievalue;
            }

            this.createCookie("VISITREFER", refercookievalue, 90);
        }
    }


    this.saveAffiliateCookie = function () {
        var aflCook = this.readCookie("AFLID");
        if (!aflCook) {
            var aflID = this.getParameterByName(window.location.href, "AFLID");
            if (aflID) {
                this.createCookie("AFLID", aflID, 90);
            }
        }
        var refCook = this.readCookie("AFLRT");
        if (!refCook) {
            var aflRT = this.getParameterByName(window.location.href, "AFLRT");
            if (aflRT) {
                this.createCookie("AFLRT", aflRT, 90);
            }
        }
    }

    this.saveTMCookie = function () {
        var a = this.readCookie("TestMasterUSA");
        if (!a) {
            this.createCookie("TestMasterUSA", "1", 1825);
        }
    }
}

$(document).ready(function () {
    var cook = new ReferCookie();
    cook.saveReferCookie();
    cook.saveTMCookie();
    cook.saveAffiliateCookie();
});

